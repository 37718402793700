<template>
  <div>
    <!-- <w-top-bar>Inscripciones</w-top-bar> -->
    <w-top-bar v-if="event" back="/products">
      <div>Inscripciones a {{ event.name }}</div>
      <div class="text-sm text-gray-500">{{ event.subtitle }}</div>
      <!-- <div>{{event.name}}</div> -->
    </w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center py-2 text-gray-900 justify-left">
        <button
          @click="show.total = false"
          v-if="show.total"
          class="text-indigo-500"
        >
          <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button @click="show.total = true" v-else class="text-indigo-500">
          <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="ml-1">Resumen General</div>
      </div>
      <div
        v-if="show.total"
        class="flex flex-wrap mb-10 space-y-3 transition duration-500 ease-in-out md:flex-nowrap md:space-y-0 md:space-x-6 1s"
      >
        <w-total-box v-if="totalPaid" label="Hoy" :value="total.todayPaid">
          <div
            class="px-5 py-1 text-xs text-gray-800 bg-gray-100 border-t border-gray-200"
          >
            <div>Total Inscriptos: {{ total.today }}</div>
          </div>
        </w-total-box>
        <w-total-box v-if="total" label="Total Clientes" :value="total.paid">
          <div
            class="px-5 py-1 text-xs text-gray-800 bg-gray-100 border-t border-gray-200"
          >
            <div>Total Inscriptos: {{ total.total }}</div>
          </div>
        </w-total-box>
        <w-total-box label="Porcentaje de Pago" :value="percentage">
          <div
            class="px-5 py-1 text-xs text-gray-800 bg-gray-100 border-t border-gray-200"
          >
            <div>Hoy: {{ percentageToday }}</div>
          </div>
        </w-total-box>
        <!-- <w-total-box v-if="totalPaid" label="Total Facturado" :value="totalPaid">
          <div class="flex flex-wrap justify-center py-1 text-xs text-gray-800 bg-gray-100 border-t border-gray-200">
            <div class="px-1 m-1 bg-indigo-200 rounded ">MP: {{total.mercadopago}}</div>
            <div class="px-1 m-1 bg-indigo-200 rounded ">Banco: {{total.wire}}</div>
            <div class="px-1 m-1 bg-indigo-200 rounded ">Paypal: {{total.paypal}}</div>
          </div>
        </w-total-box> -->
      </div>
      <div class="">
        <div class="flex items-center justify-between py-2 text-gray-900">
          <div>Inscripciones</div>
          <label
            class="flex items-center text-xs text-indigo-500 cursor-pointer"
          >
            <input
              value="sent"
              v-model="pendingCheckbox"
              type="checkbox"
              class="w-3 h-3 mr-1 form-checkbox"
            />
            Ver Solo Pendientes
            <div
              class="relative flex items-center justify-center w-4 h-4 ml-1 text-xs font-semibold text-white bg-red-500 rounded-full shadow"
            >
              <div
                class="absolute w-3 h-3 bg-red-600 rounded-full opacity-75 animate-ping"
              ></div>
              {{ total.sent }}
            </div>
          </label>
        </div>
        <div>
          <w-input
            v-model="search"
            name="Buscar Clientes"
            placeholder="Buscar Clientes"
            :rules="null"
            helper="Puedes buscar por correo o nombre"
            input-class="px-2 py-3 border-gray-500 shadow border-1"
          ></w-input>
        </div>

        <w-inscriptions
          :event-price="this.event.price.ars"
          :total-results="total.total"
          :class="{ 'opacity-50': isFindInscriptionsPending }"
          :hide="['event']"
          :items="items"
          @next-page="nextPage($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { makeFindMixin, makeGetMixin } from "feathers-vuex"; // Step 1

export default {
  name: "Dashboard",
  mixins: [
    makeFindMixin({ service: "inscriptions",qid: "search" }),
    // makeFindMixin({ service: "totals"}),
    makeGetMixin({ service: "totals"}),
    makeGetMixin({ service: "products" }),
    // makeFindMixin({ service: "products", name: "pastproducts" }),
  ], // Step 2
  data() {
    return {
      show: {
        total: true,
        image: null
      },
      upload: null,
      search: null,
      pendingCheckbox: null,
      limit: 20
    }
  },
  watch: {
    search(newValue) {
      console.log("new", newValue)
      this.findInscriptions();
    }
  },
  computed: {
    pending(){
      if(this.pendingCheckbox)
      return "sent"
      return null
    },
    percentage(){
      if(!this.total) return "0%"
      return Math.round((this.total.paid / this.total.total) * 100) + "%" 
    },
    percentageToday(){
      if(!this.total) return "0%"
      return Math.round((this.total.todayPaid / this.total.today) * 100) + "%" 
    },

    totalPaid(){
      if(!this.total) return 0;
      let number = Math.round(this.total.paidAmount);
      return Intl.NumberFormat("es-AR", {style: "currency", currency: "ARS"}).format(number)
    },
    eventId(){
      return this.$route.params.id
    },
    query() {
      let tmp = {
        eventId: this.eventId,
        deleted: { $ne: true },
        // name: {$regex: new RegExp("^walter")}
      };
      if (this.pending) tmp.payment = this.pending;
      console.log(tmp);
      return tmp;
    },    
    totalId(){
      return 1
    },
    totalParams(){
      return {
        query: {
          eventId: this.eventId
        }
      }
    },
    inscriptionsParams() {
      return {query: this.query}
    },
    inscriptionsFetchParams() {
      return {
        query: {
          ...this.query,
          search: this.search,
          $sort: {
            createdAt: -1,
          },
          $limit: this.limit,
          $select: ["eventId","customer", "customerId", "payment", "method", "createdAt", "receipt", "paidAt"]
          // $select: ["event", "eventId", "customer", "customerId", "payment", "method", "createdAt"]
        },
        debounce: 500,
      }; // Step 3
    },
    items(){
      let tmp = this.inscriptions;
      // let tmp = this.inscriptions.filter(
      //   ins => this.deleted.findIndex(insId => insId == ins._id) === -1
      // );
      if (this.search && this.search.length > 0) {
        tmp = tmp.filter(ins => {
          const srch = this.search.toLowerCase();
          if (!ins.customer)
            return false;

          if(ins.customer.name && ins.customer.name.toLowerCase().indexOf(srch) > -1)
            return true;

          if(ins.customer.email && ins.customer.email.toLowerCase().indexOf(srch) > -1)
            return true;
        });
      }
    return tmp;
    }
  },
  methods: {
    nextPage(newLimit) {
      this.limit = newLimit;
      this.findInscriptions();
    },
    reload() {
    },
    loadMore(){}
  },
};
</script>

<style lang="scss" scoped></style>
