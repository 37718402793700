<template>
    <div class="w-full overflow-hidden bg-white rounded-lg shadow md:max-w-xs">
        <div class="flex items-center p-5">
            <div class="flex-grow text-sm leading-snug">
                <div class="font-normal text-gray-700 ">{{label}}</div>
                <div class="my-2 mr-2 text-3xl font-bold leading-5 text-indigo-600">{{value}}</div>
            </div>
        </div>
        <slot>
            
        </slot>
    </div>
</template>

<script>
    export default {
        props: ["value", "label", "extraValue"]
    }
</script>

<style lang="scss" scoped>

</style>